.tui-image-editor-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-height: 300px;
    height: 100%;
    position: relative;
    background-color: #ffffff !important;
    overflow: hidden;
    letter-spacing: 0.3px;
  }

  .tui-image-editor-container .tui-image-editor-header {
      min-width: 533px;
      position: absolute;
      background-color: #ffffff !important;
      background: white;
      top: 0;
      width: 100%;
    }
.tui-image-editor-header-buttons, .tui-image-editor-header-logo{
    display: none
}

.tui-image-editor-menu {
    background-color: #ffffff;
    border-radius: 10px
}
.tui-image-editor-container .tui-image-editor-controls {
    background-color: #F7F7F7 !important;
}

.tui-image-editor-container .tui-image-editor-help-menu.top {
    width: 100%;
    align-items: flex-start;
    display: flex;
    background-color: #ffffff;
    border-radius: 10px;
    height: 66px;
    padding-left: 26px;
    padding-top: 16px;
}