@font-face {
	font-family: "Avenir";
	font-style: normal;
	font-weight: 400;
	src: url("./font/Avenir-Book.eot"); /* IE9 Compat Modes */
	src: local("Avenir"), local("Avenir"), url("./font/Avenir-Book.eot") format("embedded-opentype"),
		/* IE6-IE8 */ url("./font/Avenir-Book.woff2") format("woff2"),
		/* Super Modern Browsers */ url("./font/Avenir-Book.woff") format("woff"),
		/* Modern Browsers */ url("./font/Avenir-Book.ttf") format("truetype"),
		/* Safari, Android, iOS */ url("./font/Avenir-Book.woff2") format("svg"); /* Legacy iOS */
}

@font-face {
	font-family: "Avenir";
	font-style: normal;
	font-weight: 300;
	src: url("./font/Avenir-Light.eot"); /* IE9 Compat Modes */
	src: local("Avenir"), local("Avenir"), url("./font/Avenir-Light.eot") format("embedded-opentype"),
		/* IE6-IE8 */ url("./font/Avenir-Light.woff2") format("woff2"),
		/* Super Modern Browsers */ url("./font/Avenir-Light.woff") format("woff"),
		/* Modern Browsers */ url("./font/Avenir-Light.ttf") format("truetype"),
		/* Safari, Android, iOS */ url("./font/Avenir-Light.woff2") format("svg"); /* Legacy iOS */
}

@font-face {
	font-family: "Avenir";
	font-style: normal;
	font-weight: 600;
	src: url("./font/Avenir-Heavy.eot"); /* IE9 Compat Modes */
	src: local("AvenirBold"), local("AvenirBold"),
		url("./font/Avenir-Heavy.eot") format("embedded-opentype"),
		/* IE6-IE8 */ url("./font/Avenir-Heavy.woff2") format("woff2"),
		/* Super Modern Browsers */ url("./font/Avenir-Heavy.woff") format("woff"),
		/* Modern Browsers */ url("./font/Avenir-Heavy.ttf") format("truetype"),
		/* Safari, Android, iOS */ url("./font/Avenir-Heavy.woff2") format("svg"); /* Legacy iOS */
}

@font-face {
	font-family: "Avenir";
	font-style: normal;
	font-weight: 500;
	src: url("./font/Avenir-Medium.eot"); /* IE9 Compat Modes */
	src: local("AvenirMedium"), local("AvenirMedium"),
		url("./font/Avenir-Medium.eot") format("embedded-opentype"),
		/* IE6-IE8 */ url("./font/Avenir-Medium.woff2") format("woff2"),
		/* Super Modern Browsers */ url("./font/Avenir-Medium.woff") format("woff"),
		/* Modern Browsers */ url("./font/Avenir-Medium.ttf") format("truetype"),
		/* Safari, Android, iOS */ url("./font/Avenir-Medium.woff2") format("svg"); /* Legacy iOS */
}

@font-face {
	font-family: "Avenir";
	font-style: normal;
	font-weight: 700;
	src: url("./font/Avenir-Black.eot"); /* IE9 Compat Modes */
	src: local("AvenirBlack"), local("AvenirBlack"),
		url("./font/Avenir-Black.eot") format("embedded-opentype"),
		/* IE6-IE8 */ url("./font/Avenir-Black.woff2") format("woff2"),
		/* Super Modern Browsers */ url("./font/Avenir-Black.woff") format("woff"),
		/* Modern Browsers */ url("./font/Avenir-Black.ttf") format("truetype"),
		/* Safari, Android, iOS */ url("./font/Avenir-Black.woff2") format("svg"); /* Legacy iOS */
}

body {
	margin: 0;
	font-family: "Avenir";
	-webkit-font-smoothing: antialiased;
	background: #f7f7f7;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input[type="checkbox" i] {
	background: pink !important;
}

input[type="button"] {
	-webkit-appearance: button !important;
	height: 40;
}

.masonry-grid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 12px;
}
.masonry-grid_column {
	background-clip: padding-box;
	width: 48% !important;
}

.mansory-grid-column-3 {
	background-clip: padding-box;
	width: 33.3% !important;
}

.mansory-grid-column-5 {
	background-clip: padding-box;
	width: 20% !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
	img {
		image-rendering: -webkit-optimize-contrast;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

textarea:focus,
input:focus {
	outline: none;
}

*::-webkit-scrollbar {
	width: 4px;
	background: transparent;
}

*::-webkit-scrollbar-track {
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background-color: #999c;
	border-radius: 6px;
	width: 20px;
}

html {
	overflow-y: scroll;
}

p {
	margin-block-start: 0.5em;
	margin-block-end: 0.5em;
}
